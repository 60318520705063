import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const Breadcrumb = ({ items }) => {
	return (
		<nav className="d-none d-sm-block">
			<ol className="breadcrumb small">
				<li className="breadcrumb-item">
					<Link title="Shop" to="/shop">
						Shop
					</Link>
				</li>

				{items.map((item, index) => {
					return (
						<li
							className={`breadcrumb-item${
								items.length == index + 1 ? ' active' : ''
							}`}
							key={index}
						>
							{item.url && (
								<Link title={item.name} to={item.url}>
									{item.name}
								</Link>
							)}
							{!item.url && <>{item.name}</>}
						</li>
					);
				})}
			</ol>
		</nav>
	);
};

Breadcrumb.propTypes = {
	items: PropTypes.array,
};

export default Breadcrumb;
