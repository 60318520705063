import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Breadcrumb from '../../components/shop/breadcrumb';
import Framework from '../../components/framework';
import Product from '../../components/shop/product';
import SEO from '../../components/framework/seo';

const ProductTemplate = ({ data, location }) => {
	const products = data.allProductsCsv.edges;
	const firstProduct = products[0].node;

	const breadcrumb = [{ name: firstProduct.Category }];
	const header = {
		image: data.background.childImageSharp.fluid.src,
		subTitle: 'Shop',
		title: firstProduct.Category,
	};

	return (
		<Framework header={header} location={location}>
			<SEO
				description={`${firstProduct.Category} designed by Deniz`}
				image={data.background.childImageSharp.fluid.src}
				location={location}
				tags="denizko, denizko art, clothes, t-shirt"
				title={`${firstProduct.Category} | Denizko art shop`}
			/>
			<div className="container">
				<Breadcrumb items={breadcrumb} />

				<div className="row row-cols-1 row-cols-md-3">
					{products.map((product, index) => {
						return <Product data={product.node} key={index} />;
					})}
				</div>
			</div>
		</Framework>
	);
};

ProductTemplate.propTypes = {
	data: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

export default ProductTemplate;

export const pageQuery = graphql`
	query ProductsBySlugCategory($slug_category: String!) {
		allProductsCsv(
			filter: { fields: { slug_category: { eq: $slug_category } } }
		) {
			edges {
				node {
					...ProductFragment
				}
			}
		}

		background: file(relativePath: { eq: "shop/background.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 1140) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
