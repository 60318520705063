import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const Post = ({ data }) => {
	return (
		<div className="col mb-4">
			<div className="mb-5 p-3 text-center" key={data.id}>
				<Link title={data.Name} to={data.fields.slug}>
					<img
						alt={data.Name}
						className="img-fluid"
						loading="lazy"
						src={data.Image1}
					/>
					<span className="small">{data.Name}</span>
				</Link>
			</div>
		</div>
	);
};

Post.propTypes = {
	data: PropTypes.object.isRequired,
};

export default Post;
